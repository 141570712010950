// @ts-strict-ignore
import { isProduction } from "@shared/utils/fullscriptEnv/fullscriptEnv";

const EE_BASE_AUTH_URL = isProduction() ? "" : "/emerson";

const sharedRoutes = {
  stopImpersonating: "/a/sessions/stop_impersonating",
  logout: "/logout",
  emerson: {
    base: EE_BASE_AUTH_URL,
  },
  patientTreatmentPlans: id => `/o/patients/${id}/treatment_plans`,

  // Routes for the rupa migration pages
  rupaMigrationRoutes: {
    // Unauthenticated routes
    root: "/",
    accountType: "/account-type",
    login: "/login",
    signup: "/signup",

    // Authenticated routes
    migrate: "/o/migrate",
  },

  external: {
    support: "https://support.fullscript.com/",
    termsOfService: "https://fullscript.com/terms-of-service",
    privacyPolicy: "https://fullscript.com/privacy",
    accessibility: "https://fullscript.com/accessibility",
    privacyRightsNotice: "https://fullscript.com/prn",
    autoshipHandout: "https://fs-marketing-files.s3.amazonaws.com/handouts/autoship-education.pdf",
    shippingPolicyWholesale:
      "https://support.fullscript.com/hc/en-us/articles/360047665712-Wholesale-shipping-policy",
    patientDiscountsExplained:
      "https://support.fullscript.com/articles/im-a-patient-am-i-receiving-a-discount-off-my-order/",
    shippingPolicyRetail: "https://support.fullscript.com/articles/patient-shipping-policy/",
    refundPolicy: "https://support.fullscript.com/hc/en-us/articles/360020956252-Refunds-returns",
    protocolsMarketingPage:
      "https://support.fullscript.com/hc/en-us/articles/360046986612-Sharing-wellness-protocols-with-patients",
    profitDispensariesSupportPage:
      "https://support.fullscript.com/hc/en-us/articles/360022163832-Profit-dispensaries",
    wellevateMigrationSupport:
      "https://support.fullscript.com/hc/en-us/sections/13558530393115-Wellevate-migration",
    dispensaryAndPatientDiscountExplained:
      "https://support.fullscript.com/articles/dispensary-individual-patient-discounts/",
    legacyDiscountExplained:
      "https://support.fullscript.com/articles/dispensary-individual-patient-discounts/",
    growingYourPracticeRevenue:
      "https://support.fullscript.com/articles/growing-your-practice-revenue/",
    autoshipDiscountSettingsExplained:
      "https://support.fullscript.com/articles/autoship-save-discount/",
    autoshipTermsConditionsExplained: "https://fullscript.com/autoship-terms",
    autoshipDiscountExplained:
      "https://support.fullscript.com/articles/autoship-save-discount/#h_01H32WBESQY7Y3HABD27X3FHA8",
    patients: "https://fullscript.com/patients",
    supplementQuality: "https://fullscript.com/blog/fullscript-quality-assurance",
    termsOfServicePatients: "https://fullscript.com/terms-of-service#terms2",
    trackingDelilvery: "https://support.fullscript.com/sections/tracking-delivery/",
    howToVideo: "https://support.fullscript.com/sections/patient-videos/",
    returnsAndReplacements: "https://support.fullscript.com/sections/returns-replacements/",
    autoshipProgram: "https://support.fullscript.com/sections/autoship/",
    evidenceBasedDecisionSupport: "https://fullscript.com/blog/evidence-based-decision-support",
    emersonTerms: {
      termsOfService: "https://emersonecologics.com/terms-of-sale",
      privacyPolicy: "https://emersonecologics.com/privacy-policy",
    },
    studentOrdering: "https://fullscript.com/for/student-ordering#video",
    demo: "https://fullscript.com/demo",
    stripeConnectedAccountAgreement: "https://stripe.com/legal/connect-account",
    dispensarySalesTaxUS:
      "https://support.fullscript.com/hc/en-us/articles/13283882528923-US-dispensaries-Sales-tax",
    dispensarySalesTaxCA:
      "https://support.fullscript.com/hc/en-us/articles/13279981759771-Canadian-dispensaries-Sales-tax",
    prop65Warnings: "http://www.p65warnings.ca.gov",
    emerson: {
      support: "https://help.emersonecologics.com/hc/en-us",
    },
    wordpressGraphQL: {
      production: "https://fullscript.com/graphql",
      staging: "https://fs-mkt-staging.r.fullscript.cloud/graphql",
    },
    labsToolkit: "https://fullscript.com/labs",
  },
} as const;

export { sharedRoutes };
